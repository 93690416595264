import React, { useEffect, useRef, useState } from 'react';
import VivToast from "../../../shared/VivitechToast";
import { Button } from 'primereact/button';
import FormInput from "../../../shared/Form-Input";
import { showLoader, hideLoader } from "../../../redux/mainSlice";
import { useDispatch } from 'react-redux';
import httpClient from "../../../_util/api";
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function SimpleReset() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const toast_Ref = useRef(null);
  const query = useQuery();

  const [email, setEmail] = useState("");
  const [newPass, setNewPass] = useState({ password: "", confirmPassword: "" });
  const [token, setToken] = useState("");
  const [passwordStrengthLabel, setPasswordStrengthLabel] = useState("");
  
  
  const calculateStrength = (password) => {
    let strength = 0;
    if (password.length > 7) strength += 1;
    if (password.match(/[a-z]+/)) strength += 1;
    if (password.match(/[A-Z]+/)) strength += 1;
    if (password.match(/[0-9]+/)) strength += 1;
    if (password.match(/[$@#&!]+/)) strength += 1;
    return strength;
  };

  const getStrengthLabel = (strength) => {
    switch (strength) {
      case 0:
        return "Too Weak";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Good";
      case 5:
        return "Strong";
      default:
        return "";
    }
  };

  useEffect(() => {
    const tokenFromQuery = query.get("token");
    //const emailFromQuery = query.get("email");
    if (tokenFromQuery) {
      setToken(tokenFromQuery);
      //setEmail(emailFromQuery);
    }
  }, [query]);

  const handleNewPassword = (event) => {
    const { key, value } = event;
    setNewPass((prevFormData) => ({ ...prevFormData, [key]: value }));

    if (key === "password") {
      const strength = calculateStrength(value);
      setPasswordStrengthLabel(getStrengthLabel(strength));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(showLoader());
    try {
      const resp = await httpClient.post("password/reset", {
        token: token,
        password: newPass.confirmPassword,
        password_confirmation: newPass.confirmPassword,
      });

      if (resp.status === 200) {
        toast_Ref.current?.showMessage("success", resp?.data.status, "", "i-chk-circle");
        setTimeout(() => {
          navigate("/login");
        }, 1000);

      } else {
        toast_Ref.current?.showMessage("error", resp?.data.message, "", "i-notify");
      }
    } catch (error) {
      toast_Ref.current?.showMessage("error", error.response.data.message, "", "i-notify");
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <>
      <VivToast ref={toast_Ref} />
      <div className="grid mr-0" style={{ minHeight: "100vh" }}>

        <div className="col-12 md:col-12 pb-0 flex flex-column align-items-center align-self-center login_form">
          <img style={{ marginBottom: '2rem' }} height={100} src="/images/bh-logo.jpg" />
          {/* <form
            onSubmit={handleSubmit}
            className="px-3"
            style={{ width: "430px" }}
          >
            <p className="text-center fs f-30 fw-5 mb-5">Reset Password</p>
            <FormInput
              inputtype={"text"}
              title={"Email or Phone Number"}
              name={"username"}
              placeholder={"Enter email or phone number"}
              value={formData.username}
              required={true}
              onchange={(e) => handleChange({ name: "username", value: e })}
            />
            <Button
              className="w-full sm:mt-6"
              label="Reset Password"
              // loading={submitLoader}
              disabled={formData.username == ""}
            />
          </form> */}
          <form onSubmit={handleSubmit} className="px-3" style={{ maxWidth: '350px' }}>
            <div className="sm:hidden mt-4 text-center">
              <img src="/images/mobile-logo.svg" alt="Logo" />
            </div>
            <p className="text-center fs f-30 fw-5 mb-3">Reset Password</p>
            <p className="text-center text-grey fs f-12 mb-8">
              Reset your account password and access your account again
            </p>

            <>
              <FormInput inputtype={'password'} meterClass={"static"} styleclass={"reset-pass-container"} showMeter={true} title={'New Password'} name={'password'} placeholder={'Enter new password'} value={newPass.password} required={true} onchange={(e) => handleNewPassword({key: 'password', value: e})} />
              <FormInput inputtype={'password'} title={'Confirm Password'} name={'confirm_password'} placeholder={'Re-enter new password'} value={newPass.confirmPassword} required={true} onchange={(e) => handleNewPassword({ key: 'confirmPassword', value: e })} />
              {(newPass.password !== '' && newPass.confirmPassword !== '' && newPass.password !== newPass.confirmPassword) && <p style={{ fontSize: '11px', color: "#ff0000", fontWeight: 500 }}>Password not matched</p>}
            </>

            <Button
              type="button"
              onClick={handleSubmit}
              className="mt-4 w-full"
              disabled={
                token === "" ||
                newPass.password === "" ||
                newPass.confirmPassword === "" ||
                newPass.password !== newPass.confirmPassword || passwordStrengthLabel != "Strong"
              }
              label="Submit"
            />

            {/* <div className="for_pass mt-4 text-center">
              <p>
                Back to{" "}
                <Link to="/login" className="text-primary">
                  Login
                </Link>
              </p>
            </div> */}
          </form>
        </div>
      </div>
    </>
  )
}
