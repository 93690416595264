import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

const PageLoader = () => {

    const ShowLoader = useSelector((state) => state.mainSlice.showLoader);

    return (
        <>
            {ShowLoader && <div style={{ position: "absolute", height: "100vh", width: "100vw", zIndex: "999999", backgroundColor: "#f4f4f48a", bottom: 0 }} className="align-items-center flex fullpage-loader justify-content-center">
                <ThreeDots
                    height="60"
                    width="60"
                    color="#fe4215"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    radius="9"
                    ariaLabel="three-dots-loading"
                    // outerCircleColor="#2054D9"
                    // innerCircleColor="#0097FE"
                    // middleCircleColor=""
                />
            </div>}
        </>
    )
}

export default PageLoader;
