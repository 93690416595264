import React, { useEffect, useState } from "react";
import { Route, useNavigate, useLocation } from "react-router-dom";
import httpClient from "./api";
import { useDispatch, useSelector } from "react-redux";
import { setBadgeValue, setNotificationCount, setIsLegacyMember, setLocation_id, setProfilePic, setRole, setUserDetail, setUserMenus, setUserRole } from "../redux/mainSlice";
import { hideMobileBar } from "../redux/sidebarSlice";

const ProtectedRoute = (props) => {
  const userMenus = useSelector((state) => state.mainSlice.userMenus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem("user-token")));

  const checkUserToken = async () => {
    const userToken = localStorage.getItem("user-token");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate("/login");
    }
    
    let apiResp = await httpClient.get("whoami").catch((error) => { localStorage.removeItem("user-token"); });

    if (apiResp?.status == 200) {
      dispatch(setUserMenus(apiResp.data.menus));
      dispatch(setUserRole(apiResp.data.role));
      dispatch(setUserDetail(apiResp.data.user));
      dispatch(setRole(apiResp.data.role ? apiResp.data.role : null))
      dispatch(setProfilePic(apiResp.data.user?.image_path));
    }

    setIsLoggedIn(true);
  };

  useEffect(() => { 
    dispatch(hideMobileBar());
    if(props?.allowRender == false){
      navigate('/AccessNotAllowed');
    }
  });
  
  useEffect(() => {
     checkUserToken();
  }, [isLoggedIn]);

  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};

export default ProtectedRoute;
