import React, { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import FormInput from "../shared/Form-Input";
import { Button } from 'primereact/button';
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../redux/mainSlice";
import VivToast from "../shared/VivitechToast";
import httpClient from "../_util/api";


const NewUserChangePwdDialog = ({ token, user, onSuccess }) => {
    const toast_Ref = useRef(null);
    const [newPass, setNewPass] = useState({ password: "", confirmPassword: "" });
    const [passwordStrengthLabel, setPasswordStrengthLabel] = useState("");
    const dispatch = useDispatch();

    const calculateStrength = (password) => {
        let strength = 0;
        if (password.length > 7) strength += 1;
        if (password.match(/[a-z]+/)) strength += 1;
        if (password.match(/[A-Z]+/)) strength += 1;
        if (password.match(/[0-9]+/)) strength += 1;
        if (password.match(/[$@#&!]+/)) strength += 1;
        return strength;
    };

    const getStrengthLabel = (strength) => {
        switch (strength) {
            case 0:
                return "Too Weak";
            case 1:
                return "Weak";
            case 2:
                return "Fair";
            case 3:
                return "Good";
            case 4:
                return "Good";
            case 5:
                return "Strong";
            default:
                return "";
        }
    };

    const handleNewPassword = (event) => {
        const { key, value } = event;
        setNewPass((prevFormData) => ({ ...prevFormData, [key]: value }));

        if (key === "password") {
            const strength = calculateStrength(value);
            setPasswordStrengthLabel(getStrengthLabel(strength));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(showLoader());
        try {
            const resp = await httpClient.post("/password-update", {
                token: token,
                password: newPass.confirmPassword,
                password_confirmation: newPass.confirmPassword,
            });

            if (resp.status === 200) {
                toast_Ref.current?.showMessage("success", "Password updated successfully", "", "i-chk-circle");
                onSuccess(resp?.data.token);
            } else {
                toast_Ref.current?.showMessage("error", resp?.data.message, "", "i-notify");
            }
        } catch (error) {
            console.log(error);
            
            toast_Ref.current?.showMessage("error", error?.response?.data?.message, "", "i-notify");
        } finally {
            dispatch(hideLoader());
        }
    };

    return (
        <>
            <VivToast ref={toast_Ref} />
            <Dialog
                visible={true}
                onHide={() => { }}
                className="viv_dialog newuser-dialog"
                modal
                header={''}
                draggable={false}
                resizable={false}
                closable={false}
            >
                <div>
                    <h3>Welcome!</h3>
                    <h5>{user?.name}</h5>
                    <p style={{ marginTop: '10px', marginBottom: '20px' }}>To make your account secure, please create a new password to replace the temporary password you were given in the email invitation.</p>
                </div>
                <FormInput inputtype={'password'} meterClass={"static"} styleclass={"reset-pass-container"} showMeter={true} title={'New Password'} name={'password'} placeholder={'Enter new password'} value={newPass.password} required={true} onchange={(e) => handleNewPassword({ key: 'password', value: e })} />
                <FormInput inputtype={'password'} title={'Confirm Password'} name={'confirm_password'} placeholder={'Re-enter new password'} value={newPass.confirmPassword} required={true} onchange={(e) => handleNewPassword({ key: 'confirmPassword', value: e })} />
                {(newPass.password !== '' && newPass.confirmPassword !== '' && newPass.password !== newPass.confirmPassword) && <p style={{ fontSize: '11px', color: "#ff0000", fontWeight: 500 }}>Password not matched</p>}

                <Button
                    type="button"
                    onClick={handleSubmit}
                    className="mt-4 w-full"
                    disabled={newPass.password === "" ||
                        newPass.confirmPassword === "" ||
                        newPass.password !== newPass.confirmPassword || passwordStrengthLabel != "Strong"
                    }
                    label="Submit"
                />
            </Dialog>
        </>

    )
}

export default NewUserChangePwdDialog;