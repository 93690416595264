import React, { useState, useRef, useEffect } from "react";
import { Route, useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import Notification from "./Notification";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader, mainSlice, setLocation_id } from "../redux/mainSlice";
import { showMobileBar } from "../redux/sidebarSlice";
import { OverlayPanel } from 'primereact/overlaypanel';
import { Divider } from 'primereact/divider';
import ConfirmationPopup from "../shared/ConfirmationPopup";
import httpClient from "../_util/api";
import FromSelect from "../shared/Form-Select";
import FormInput from "../shared/Form-Input";
import NewUserChangePwdDialog from "./NewUserChangePassword";

const AppTopBar = () => {
  const toast_Ref = useRef(null);
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.mainSlice.userdetail);
  const location_id = useSelector((state) => state.mainSlice.locationId);
  const userRole = useSelector((state) => state.mainSlice.role);
  const profilePicPath = useSelector((state) => state.mainSlice.profilePicUrl);
  const confirmationPopupRef = useRef(null);
  const [locationId, setLocationId] = useState()
  const [locationDetails, setLocationDetails] = useState()
  const userImage = useSelector((state) => state.mainSlice.userImage);
  const [pageTitle, setPageTitle] = useState("");

  const op = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [activeAccount, setActiveAccount] = useState('active');

  let location = useLocation();

  const handleLogout = () => {
    confirmationPopupRef.current?.showPopUp();
  };

  const setLocation = async (value) => {
    setLocationId(value)
    dispatch(setLocation_id(value))
  }

  const fetchLocations = async () => {
    try {
      const { data } = await httpClient.get("/user-locations");
      if (data.status == 200) {
        setLocationDetails(data.data)
        setFilteredLocations(locationDetails);

        if (location_id == null) {
          // setLocationId(data.data[0].id)
          dispatch(setLocation_id(data.data[0].id))
        }

      }
    } catch (error) {
    }
  };

  useEffect(() => {
    const pathSegments = location?.pathname?.split('/').filter(Boolean);
    let lastSegment;
    if (pathSegments.length > 1) {
      lastSegment = pathSegments[1];
      if (pathSegments?.length == 3 && pathSegments?.includes('detail')) {
        lastSegment = pathSegments[0];
      }
    } else {
      lastSegment = pathSegments[pathSegments?.length - 1];
    }
    const formattedTitle = lastSegment?.replace('-', ' ');
    setPageTitle(formattedTitle);
  }, [location.pathname])



  const accept = async () => {
    dispatch(showLoader());
    try {
      const resp = await httpClient.post("logout");
      if (resp.status === 200) {
        dispatch(hideLoader());
        dispatch(mainSlice.actions.clearUserDetail());
        dispatch(mainSlice.actions.clearUserRole());
        dispatch(mainSlice.actions.clearUserMenus());
        dispatch(mainSlice.actions.clearLocation_id());
        localStorage.removeItem('user-token');
        localStorage.removeItem('switch_account');
        navigate("/login");
      }
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "An error occurred",
        "",
        "ic-error-notify"
      );
      dispatch(hideLoader());
    }

  };

  return (
    <>
    {/* <NewUserChangePwdDialog /> */}
      <div className="viv_header viv_mobileheader md:hidden align-content-center align-items-center flex justify-content-between">
        <Button className="hidden-lg hidden-md menu-toggle" icon={'pi i-menu'} onClick={() => { dispatch(showMobileBar()) }} />
        <h1 className="block topbar-title capitalize">{pageTitle}</h1>
      </div>

      <div className="viv_header viv_webheader align-content-center align-items-center flex justify-content-between">

        <div className="md:flex md:gap-1 hidden"></div>

        <ConfirmationPopup
          ref={confirmationPopupRef}
          closable={true}
          title="Logout"
          message="Are you sure you want to logout?"
          onConfirm={() => {
            accept(); // Handle form submission on "Yes"
            confirmationPopupRef.current?.hidePopUp(); // Hide popup
          }}
          onCancel={() => confirmationPopupRef.current?.hidePopUp()}
          onHide={() => confirmationPopupRef.current?.hidePopUp()}// Hide popup on "No"
        />



        <div className="user_info align-items-center flex justify-content-between">

          <div className="align-items-center flex justify-content-between flex-row-reverse md:flex-row">
            <Notification />
          </div>
          
          <img
            style={{ borderRadius: "50%" }}
            width={50}
            height={50}
            src={profilePicPath ? profilePicPath : "/images/profile-pic.png"}
            onClick={(event) => menuLeft.current.toggle(event)}
            onError={(e) => {
              e.target.src = "/images/profile-pic.png";
            }}
            className="cursor-pointer top-bar-img"
          />
          
          <div className="align-items-center flex">
            
            {/* Custom Profile Menu */}
            <OverlayPanel ref={menuLeft} className="popup_userInfo">
              <div className="profile-menu">

                <div className="profile-section">
                  <img
                    src={profilePicPath ? profilePicPath : "/images/profile-pic.png"}
                    className="profile-image"
                    onError={(e) => {
                      e.target.src = "/images/profile-pic.png";
                    }}
                  />
                  <div className="profile-details">
                    <div className="profile-name">{userInfo?.name ? userInfo?.name : '-'}</div>
                    <div className="profile-email">{userInfo?.email ? userInfo?.email : '-'}</div>
                  </div>
                </div>
              
                <Divider />

                <div className="menu-items">
                  <div className="menu-item" onClick={(e) => { navigate("/profile"); menuLeft.current.toggle(e);}} >
                    <i className="pi pi-user" style={{ marginRight: '8px' }}></i>
                    Profile
                  </div>
                  <div className="menu-item mt-2" onClick={handleLogout} >
                    <i className="pi pi-sign-out" style={{ marginRight: '8px' }}></i>
                    Log out
                  </div>
                </div>
              </div>
            </OverlayPanel>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppTopBar;
