import axios from 'axios';

const httpClient = axios.create({
    //baseURL: "https://api.dev.vivitech.solutions/api/",
    baseURL: process.env.REACT_APP_MIDDLEWARE_URL,
});

httpClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('user-token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers.Accept = 'application/json';
    return config;
}, function (error) { return Promise.reject(error); });

httpClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        localStorage.removeItem('user-token');
        window.location.href = '/login';
    }
    return Promise.reject(error);
});

export default httpClient;