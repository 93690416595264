import React from 'react'
import SimpleReset from './SimpleReset'
import ImageReset from './ImageReset'

export default function ResetPassword({ temp }) {
  return (
    <>
      {temp === "plain" && <SimpleReset />}
      {temp === "image" && <ImageReset />}
    </>
  )
}
