import React from 'react'
import "./shared.scss"

const LoginSection = () => {
  return (
    <>
      <div className='viv_login-bg' >
        {/* <img src='/images/main_logo.svg'></img>
        <p className='mt-5 '>Boilerplate Dashboard Template!</p> */}
      </div>
    </>
  )
}

export default LoginSection