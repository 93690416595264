import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuLink = (props) => {
    const location = useLocation();
    const label = props.label ? props.label.toLowerCase() : "";
    const isActive = location.pathname.includes(props.to);
    // const isActive = location.pathname.includes(props.to) || location.pathname.includes(label);
    // const isActive = location.pathname.includes(props.to);
    // const isActive = location.pathname == props.to;

    console.log("props.to: ", props.to);
    console.log("location: ", location.pathname);
    console.log("active? ", isActive);
    console.log("PROPS: ", props);

    return(
        <Link to={props.to} className={`${props.classes} ${isActive ? 'active' : ''}`}>
            <span className={`p-menuitem-icon ${props.icon}`} data-pc-section="headericon"></span>
            <span className="p-menuitem-text" data-pc-section="headerlabel">{props.text}</span>
        </Link>
    )
}

export default MenuLink;
